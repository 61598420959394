body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  width: 100%;
  background-color: #eee;
}

.app {
  width: 100%;
  height: 100%;
  position: absolute;
}

.app-container {
  min-height: calc(100% - 270px);
}

.main-container {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 35px;
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  /* margin-top: '-30px'; */
  background-color: yellow;
}

.one-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  box-sizing: border-box;
}

.two-columns {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.left-side,
.right-side {
  flex: 1;
}

.right-side {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-left: 5;
}

.left-side {
  margin-right: 5;
}

.support-programme-title {
  color: #09334B;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
}

.accordion-container a {
  text-decoration: none;
  color: #00aeef;
}

.custom-popup {
  width: 550px;
}

.tooltip {
  position: absolute;
  margin: 8px;
  padding: 4px;
  background: white;
  color: black;
  max-width: 300px;
  font-size: 10px;
  z-index: 9;
  pointer-events: none;
}

/* header menu */
.menu-center {
  display: flex;
  flex-direction: 'row';
  flex: 3;
  justify-content: 'space-evenly';
  align-items: 'center';
  height: '100%'
}

.header-burger-menu {
  display: none;
  color: '#004165';
  font-size: 32px;
  font-weight: 500;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
}

.header-burger-menu img {
  display: block;
  height: 60px;
}

.mobile-active,
.mobile-hide {
  position: absolute;
  top: 100px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
  background-color: white;
  padding: 10px 0px;
  transition: ease-in-out 0.8s;
  box-shadow: rgba(17, 17, 26, 0.1) 2px 2px 3px;
  z-index: 1;
  height: 350px;
}

.mobile-active .cwas-logo,
.mobile-hide .cwas-logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 40px);
  padding: 10px 20px;
}

.mobile-active .cwas-logo img,
.mobile-hide .cwas-logo img {
  height: 50px;
  margin-right: 10px;
}
.mobile-active .cwas-logo .brand,
.mobile-hide .cwas-logo .brand {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: nowrap;
}
.mobile-active .cwas-logo .brand .brand-title,
.mobile-hide .cwas-logo .brand .brand-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: #09334B;
}
.mobile-active .cwas-logo .brand .brand-desc,
.mobile-hide .cwas-logo .brand .brand-desc {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #3A5768;
}
.mobile-active a,
.mobile-hide a {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #09334B;
  padding: 10px 20px;
  width: calc(100% - 40px);
  box-sizing: border-box;
  white-space: nowrap;
}
.mobile-active a:hover,
.mobile-hide a:hover {
  color: #00A4EC;
  background-color: #F2F5F7;
}
.mobile-active a.active,
.mobile-hide a.active {
  color: #00A4EC;
  font-weight: 600;
}
.mobile-active {
  display: none;
}
.mobileOnly{
  display: none;
}
.cwas-logo{
  padding: 15px;
}
.cwas-logo img{
  width: 50px;
  float: left;
  margin-right: 10px;
}
.cwas-logo .brand-title{
  font-size: 20px;
}
.cwas-logo .brand-desc{
  font-size: 12px;
}
.desktopOnly{
  display: block!important;
}

@media screen and (max-width: 1400px) {
  .header-burger-menu {
    display: flex;
  }
  .mobile-active {
    right: 10px;
    display: flex;
  }
  .menu-center {
    padding: 0 0 10px;
    position: absolute;
    top: 100px;
    flex-direction: column;
    background-color: white;

  }
  .menu-center a{
    width: 100%;
    flex: none;
    border: none;
    display: block;
    white-space: nowrap;
    padding: 12px 15px;
  }
  .menu-center a p{
    padding: 0;
    font-size: 14px;
  }
  .menu-center a:hover{
    background-color: #eeeeee;
  }
  .menu-center .css-542ovh{
    position: relative;
    top: auto;
  }
  .mobileOnly{
    display: block;
  }
  .desktopOnly{
    display: none !important;
  }
  .brand{
    white-space: nowrap;
  }
  .mobileHide {
    width: 0;
    right: -270px;
    transition: ease-in-out 0.5s;
    overflow: hidden;
  }
  .mobileShow {
    width: 250px;
    right: 10px;
    transition: ease-in-out 0.5s;
  }
}

/* data collection */
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.title-support-programme {
  color: #09334B;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.support-body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.support-desc-container {
  border: 2px solid #CFDBE2;
  border-radius: 5px;
}

.accordion-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #CFDBE2;

}

.accordion-container p {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.Mui-expanded {
  margin: 0 !important;
}

.accordion-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #09334B;
  cursor: pointer;
}

.accordion-header-active {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #09334B;
  background-color: #F2F5F7;
  border: 1px solid #CFDBE2;
  cursor: pointer;
  background-color: rgb(239, 238, 238);
}

.accordion-header:hover {
  background-color: rgb(239, 238, 238);
}

.accordion-body {
  padding: 10px;
  border: 1px solid #CFDBE2;
}

.accordion-body p {
  font-size: 12px;
  font-weight: 400;
  color: #09334B;
  margin-bottom: 5px;
}

.accordion-body-hide {
  display: none;
}

a {
  text-decoration: none;
  color: #00a4ec;
  font-size: 12px;
}

/* about */
.btn-box {
  background-color: white;
  color: #9e9e9e !important;
  border: 2px solid #00aeef !important;
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.btn-box:hover {
  background-color: #00aeef !important;
  color: white !important;
}

ul.dashed li:before {
  content: '-';
  position: block;
  margin-left: -20px;
  margin-right: 14px;
}

ul.bullet li:before {
  content: 'o';
  font-size: 8px;
  position: block;
  margin-top: 4px;
  margin-left: -20px;
  margin-right: 14px;
}

ul.bullet-filled li:before {
  content: '\2022';
  position: block;
  margin-left: -20px;
  margin-right: 14px;
}

ul.four-columns {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;

  li .no-data {
    padding-left: 7px;
    font-size: 13.5px;
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  li .no-data {
    cursor: not-allowed;
  }
}

ul.four-columns li a:hover {
  color: color(undhiDarkBlue);
}


button {
  text-transform: none;
}

.paper {
  margin: 5px;
}

.aboutTab {
  width: 100%;
  background-color: color(white);
}

.MuiTab-root {
  min-width: 0;
  padding: 6px 50px;
}

.accordion-container {
  display: flex;
  flex-direction: column;
}

.accordion-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #004165;
  border: 1px solid lightgrey;
  cursor: pointer;
}

.accordion-header-active {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #004165;
  border: 1px solid lightgrey;
  cursor: pointer;
  background-color: rgb(239, 238, 238);
}

.accordion-header:hover {
  background-color: rgb(239, 238, 238);
}

.accordion-body {
  padding: 10px;
  font-size: 14px;
  font-weight: 300;
  color: #09334B;
  border: 1px solid lightgrey;
}

.accordion-body-hide {
  display: none;
}

.title-about {
  color: #09334B;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
  padding: 10px;
  text-align: start;
}

/* react-map-gl */
.custom-popup {
  width: fit-content !important;
  max-width: 600px !important;
}


.custom-popup .mapboxgl-popup-anchor-bottom, .custom-popup .mapboxgl-popup-anchor-bottom-left, .custom-popup .mapboxgl-popup-anchor-bottom-right {
  flex-direction: column-reverse;
  width: fit-content !important;
  max-width: 600px !important;
}

.custom-popup .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  align-self: center;
  border-bottom: none;
  border-top-color: #fff;
}

.custom-popup .mapboxgl-popup-tip {
  border: 10px solid transparent;
  height: 0;
  width: 0;
  z-index: 1;
  position: absolute;
  bottom: -10px; /* Ensure this has the correct unit */
  left: 50%; /* Set to 50% to start centering from the middle */
  transform: translateX(-50%); /* Move to the left by half of its own width */
}

.custom-popup .mapboxgl-popup-content {
  background: #fff;
  box-sizing: border-box;
  padding: 5px;
  width: fit-content;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
  padding: 10px 10px 15px;
  pointer-events: auto;
  position: relative;
}


.cms{
  height: calc(100% - 189px);
  padding: 20px 0;
  overflow-y: auto;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
}

.cms .dndContainer{
  padding: 0;
}

.cms .dndList{
  background-color: #FFFFFF;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.jodit-react-container{width: 100%;}

.jodit360 .jodit-wysiwyg{
  height: 360px !important;
}

.jodit410 .jodit-wysiwyg{
  height: 410px !important;
}

.jodit-wysiwyg p{
  margin-top: 0;
}

.CMSAppName{
  padding: 0 15px !important;
  font-size: 16px !important;
  color: white;
  white-space: noWrap;
}

.CMSActiveUser{
  padding: 0 10px !important;
  font-size: 14px !important;
  color: white;
  white-space: noWrap;
}

.CMSMobileMenuBtn{
  display: none !important;
}

.CMSHeader{
  height: 100px;
}

.CMSLogo{
  width: 100px;
  height: 100px;
}

.CMSCoverHolderSize{
  height: 600px;
}

::-ms-reveal {
  display: none;
}

.css-9ir5sl-MuiFormLabel-root-MuiInputLabel-root{
  top: -6px !important;
}

.css-1kek70v-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  top: 0px !important;
}

.reportGridSetup{
  margin-bottom: 10px !important;
}

.uploadReportBtn, .deleteReportBtn{
  margin: 0 auto 10px !important;
}

.browseReportFile{
  position: absolute !important;
  top: 12px;
  right: 3px;
  min-width: auto !important;
  font-size: 16px !important;
  color: white !important;
  margin: 0 !important;
  padding: 5px !important;
}

.browseReportFile > span{
  margin: 0 !important;
}

.uploadReportBtn .hidden, .deleteReportBtn .hidden, .browseReportFile .hidden{
  font-size: 15px;
  display: none;
}

@media screen and (max-width: 1024px) {
  .CMSMobileMenuBtn{
    display: inline-flex !important;
    color: white !important;
  }
  .CMSMobileMenu{
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .CMSHeader{
    height: 60px;
  }
  .CMSLogo{
    width: 60px;
    height: 60px;
  }
  .CMSMobileTextHide, .CMSAppName, .CMSActiveUser{
    display: none;
  }
  .cms{
    height: calc(100% - 100px);
  }
  .cms .dndList{
    background-color: #FFFFFF;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    padding: 0;
    display: block;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    height: inherit;
  }
  /* .cms .dndList.noImage{
    height: inherit;
  } */
  .CMSMobileDragIcon{
    position: absolute !important;
    top: 6px;
    left: 10px;
    z-index: 1;
  }
  .CMSMobileActiveSwitch{
    position: absolute !important;
    top: 6px;
    left: 40px;
    z-index: 1 !important;
  }
  .CMSMobileListImageDisplay{
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
    border-radius: 4px;
    z-index: 0;
  }
  .CMSInviShadow{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 0;
    background: rgb(2,0,36);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: linear-gradient(180deg, rgba(225,225,225,1) 0%, rgba(225,225,225,0.5) 50%, rgba(225,225,225,0) 100%); 
  }
  .CMSMobileTitleList{
    position: relative;
    padding: 10px;
    margin-top: 120px !important;
    margin-bottom: 0;
    color: white;
    font-size: 14px !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
  }
  .CMSMobileTitleList.noImage{
    margin-top: 50px !important;
    margin-bottom: 0;
  }
  .CMSMobileEditBtn{
    position: absolute !important;
    top: 6px;
    right: 60px;
  }
  .CMSMobileDeleteBtn{
    position: absolute !important;
    top: 6px;
    right: 0;
  }
  .CMSMobileEnterBtn{
    position: absolute !important;
    top: 6px;
    right: 18px;
  }
  .CMSCoverHolderSize{
    height: 200px !important;
  }
  .reportGridSetup{
    margin-bottom: 0 !important;
  }
  .uploadReportBtn, .deleteReportBtn{
    margin: 0 auto 10px !important;
  }
  .uploadReportBtn .hidden, .deleteReportBtn .hidden, .browseReportFile .hidden{
    display: block;
    margin-left: 5px;
  }

  .browseReportFile > span{
    margin: 0 5px 0 0 !important;
  }
}